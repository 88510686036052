<template>
  <div class="hper wper bg-primary2">
    <graphvis ref="graphvis" @onClickNode="onClickNode" @onDBClickNode="onDBClickNode" @onClickLink="onClickLink" @onRightClickNode="onRightClickNode" />
  </div>
</template>
<script>
  export default {
    data() {
      return {
        id: 3,
        nodes: [
          { id: 1, label: '点1', properties: {}, type: 'user' },
          { id: 2, label: '点2', properties: {}, type: 'group' },
        ],
        links: [
          { source: 1, target: 2, label: '群主', properties: {} }
        ],
        typeList: [
          '银行卡','虚拟币','blued','滴滴打车','抖音','钉钉'
        ]
      }
    },
    mounted() {
      setTimeout(() => {
        this.$refs.graphvis.createGraph()
        this.init()
      }, 500)
    },
    methods: {
      init() {
        const { nodes, links } = this
        this.$refs.graphvis.addGraph({nodes, links})
        this.$refs.graphvis.excuteLocalLayout()
      },
      activeAddData(node) {
        const nodes = [], links = []
        for (let i = 0; i < 6; i++) {
          this.id++
          nodes.push({ id: this.id, label: `点${this.id}`, properties: {}, type: this.typeList[parseInt(Math.random() * 6)] })
          links.push({source: node.id, target: this.id, label: '加入的'})
        }
        this.$refs.graphvis.activeAddData(node, {nodes, links}, 'fastForce')
      },
      onRightClickNode(e) {
        this.$contextmenu({
          items: [
            {
              // icon: "el-icon-circle-plus-outline",
              label: "添加",
              hidden: false,
              onClick: () => {
                this.activeAddData(e.node)
              }
            },
            {
              label: "删除",
              hidden: false,
              onClick: () => {
                this.$refs.graphvis.deleteNode(e.node)
              }
            },
          ],
          event: e.e, // dom
          // customClass: "class-a", // 类名
          // zIndex: 3, // 层级
          // minWidth: 100 // 最小宽度
        })
      },
      onClickNode(e) {
        this.$message.success('单击了顶点')
      },
      onDBClickNode(e) {
        this.activeAddData(e.node)
        this.$message.success('双击顶点')
      },
      onClickLink(e) {
        this.$message.success('单击了边')
      },
    }
  }
</script>